export type RelType = {
    question_1: string,
    question_2?: string,
    answer_1?: number,
    answer_2?: number,
    res?: number,
    getImpact: (...arg0: any[]) => number
}

export const Rel: RelType[] = [
    {
        question_1: "¿Cuántas personas viven en tu hogar?",
        question_2: "Ve la última factura de agua que recibiste. En la misma se especifica tu consumo de agua en m3. Anota tu consumo del último mes.",
        getImpact(q_person: number, m3_used: number) {
            try{
                return (m3_used * 1000 * 12) / q_person;
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántas veces al día utilizas el inodoro?",
        getImpact(q_goto_bathroom: number) {
            try{
                return q_goto_bathroom * 365 * 8;
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántas veces a la semana usas la ducha?",
        question_2: "¿Cuántos minutos tardas en ducharte cada vez?",
        getImpact(q_bathe_w: number, q_min_bathe: number) {
            try{
                return q_bathe_w * q_min_bathe * 10 * 52;
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántas veces al día lavas o se lavan tus platos?",
        question_2: "¿Cuántos minutos aproximadamente se requieren para lavar tus platos?",
        getImpact(q_plate_wash: number, q_wash_m: number) {
            try{
                return q_plate_wash * q_wash_m * 8 * 365;
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántas veces lavas o se lava tu ropa a la semana? (En lavadora)",
        question_2: "¿Cuántas veces lavas o se lava tu ropa a la semana? (A mano)",
        getImpact(q_wash_mch: number, q_wash_hand: number) {
            try{
                return (q_wash_mch * 60 * 52) + (q_wash_hand * 50 * 52);
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántos veces riegas o se riega el jardín de tu hogar al mes?",
        question_2: "¿Cuántos tiempo toma regar el jardín de tu hogar?",
        getImpact(q_times_garden_watering: number, q_time_m: number, q_person) {
            try{
                return (q_times_garden_watering * q_time_m * 12 * 12) / q_person;
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántos automóviles tienen en tu hogar?",
        question_2: "¿Cuántas veces al mes lavas tu automóvil?",
        getImpact(q_car_has: number, q_time_user_mt, q_person: number) {
            try{
                return (q_car_has * q_time_user_mt * 50 * 12) / q_person;
            }catch(err){
                return 0;
            }
        },
    },
    {
        question_1: "¿Cuántas veces al año llenas tu piscina con agua?",
        answer_1: 0,
        getImpact(q_fill: number, q_person: number) {
            try{
                return (q_fill * 9000) / q_person;
            }catch(err){
                return 0;
            }
        },
    }
]