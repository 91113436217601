import { useEffect, useRef, useState } from 'react';
import './App.css';
import { madePost } from './common/post';
import { Rel, RelType } from './common/rel';
import AnswerCard from './components/AnswerCard';

function App() {
  const [q_person, setQPerson] = useState(1);
  const [tab, setTab] = useState(-1);
  const [rel, setRel] = useState(Rel);

  const [consume, setConsume] = useState(0);
  const [consumeIndirect, setConsumeIndirect] = useState(0);

  const [isValid, setIsValid] = useState(false);

  const nameRef = useRef({} as HTMLInputElement);
  const emailRef = useRef({} as HTMLInputElement);

  const [cred, setCred] = useState({ email: "", name: "" });

  const analize = (ev: any) => {
    if (
      nameRef.current.value.length > 4 &&
      emailRef.current.value.length > 5
    ) {
      setIsValid(true);
    } else {
      setIsValid(false)
    }

  }

  const calcHH = () => {
    let res = 0;

    rel.forEach((c: RelType) => {
      if (c.res) {
        res += c.res;
      }
    })

    setConsume(res);
    setConsumeIndirect(res + 1635200);

    return res;
  }

  useEffect(() => {
    if (tab >= 0) {
      calcHH();
    }
  }, [rel, tab])

  useEffect(()=>{
    if(tab == 8){
      madePost(
        {
          key: "add-h2o",
          name: cred.name,
          email: cred.email,
          result: JSON.stringify(rel),
          consume: consume,
          consumeIndirect: consumeIndirect
        },
        "saveh2o.php"
      )
    }
  }, [tab])

  return (
    <div className="app sm:mx-auto h-screen flex flex-col justify-center items-center">
      <div className="container w-full sm:w-2/3 md:w-2/4 mx-auto  px-10">
        <h1 className='text-center font-semibold pt-4'><i>Calcula tu impacto hídrico</i></h1>

        <div className={"answer-card h-full flex-col items-center justify-center gap-4 " + (tab == -1 ? "flex" : "hidden")}>
          <div className="w-full flex flex-col">
            <span className="block text-sm font-medium leading-6 text-gray-900">Nombre completo</span>
            <input onInput={analize} autoFocus type={"text"} ref={nameRef} placeholder="Respuesta" className="mt-2 block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6" />
          </div>
          <div className="w-full flex flex-col">
            <span className="block text-sm font-medium leading-6 text-gray-900">Email</span>
            <input onInput={analize} autoFocus type={"text"} ref={emailRef} placeholder="Respuesta" className="mt-2 block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6" />
          </div>
          <button disabled={!isValid} onClick={() => {
            setCred(
              {
                email: emailRef.current.value,
                name: nameRef.current.value
              }
            );
            setTab(0)
          }} type="submit" className="ml-auto disabled:opacity-80 inline-flex justify-center rounded-md py-2 px-3 text-sm font-semibold text-white bg-sky-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Comenzar</button>
        </div>

        {
          rel.map((re: RelType, idx: number) => {
            return <AnswerCard rel={re} idx={idx} className={tab == idx ? "flex" : "hidden"} onSave={(r: RelType, index: number) => {
              let relCopy: RelType[] = rel;
              relCopy[index] = r;

              let qperson = r.answer_1 as number;
              if (index == 0) {
                if (qperson == 0) {
                  qperson = 1;
                }
                setQPerson(qperson);

                relCopy[index].answer_1 = qperson;
              } else {
                qperson = q_person;
              }

              switch (index) {
                case 0:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, relCopy[index].answer_2); break;
                case 1:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1); break;
                case 2:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, relCopy[index].answer_2); break;
                case 3:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, relCopy[index].answer_2); break;
                case 4:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, relCopy[index].answer_2); break;
                case 5:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, relCopy[index].answer_2, q_person); break;
                case 6:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, relCopy[index].answer_2, q_person); break;
                case 7:
                  relCopy[index].res = relCopy[index].getImpact(relCopy[index].answer_1, q_person); break;
              }

              setTab(tab + 1);
              setRel(relCopy);

              console.debug(relCopy[index].res)
            }} 
            onPrev={()=>{
              setTab(tab - 1)
            }}
            />
          })
        }

        <div className={"answer-card h-full flex-col items-center justify-center " + (tab == 8 ? "flex" : "hidden")}>
          <span className="w-full">Tu huella hídrica directa es: <b>{consume}</b></span>
          <span className="w-full">Tu consumo de agua es:
            {
              consume > 36500 ?
                <b className="text-red-600 ml-2">ALTO</b>
                :
                (
                  consume == 36500 ?
                    <b className="text-green-600 ml-2">MEDIO</b>
                    :
                    <b className="text-yellow-400 ml-2">BAJO</b>
                )
            }
          </span>

          <h1 className='text-center font-semibold pt-10 pb-4'><i>Calcula tu impacto hídrico indirecto</i></h1>
          <div className="w-full flex flex-col">
            <span className="w-full block text-sm font-medium leading-6 text-gray-900">¿Qué tipo de dieta tienes?</span>
            <select onChange={(ev: any)=>{
              setConsumeIndirect(consume + parseInt(ev.target.value));
            }} className="mt-2 block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6" >
              <option value="1635200">Carnívora </option>
              <option value="1032950">Vegetariana </option>
              <option value="868700">Vegana </option>
            </select>
          </div>
          <span className="w-full mt-4">Tu huella hídrica indirecta es: <b>{consumeIndirect}</b></span>

        </div>

      </div>
    </div>
  );
}

export default App;
