
import axios from "axios";

export let postTo = (to: string, data: FormData, config?:any) => {
    return new Promise((res, rej)=>{
        axios.post(to, data, config).then((r:{data: any})=>{
            res(r.data);
        }).catch((err: any)=>{
            rej(err);
        })
    })
}

export let getTo = (to: string) => {
    return new Promise((res, rej)=>{
        axios.get(to).then((r:{data: any})=>{
            res(r.data);
        }).catch((err: any)=>{
            rej(err);
        })
    })
}

export let madePost = (values: Object, to: string) => {
    let form = new FormData();
    Object.keys(values).map((key: any, index: number)=>{
        form.append(key, (values as any)[key]);
    });

    return post(to, form, {});
}

let post = (to: string, data: FormData, config?:any) => {
    return postTo("https://administrador.nuevaeconomia.com.bo/api/" + to, data, config);
}
export default post;