import { useRef } from "react";
import { RelType } from "../common/rel";

export const AnswerCard = (props: { rel: RelType, idx: number, className: string, onPrev: () => any, onSave: (rel: RelType, index: number) => any }) => {

    const q1Ref = useRef({} as HTMLInputElement);
    const q2Ref = useRef({} as HTMLInputElement);

    const save = () => {
        let q1 = 0;
        let q2 = 0;
        if (props.rel.question_1) {
            q1 = parseFloat(q1Ref.current.value ? q1Ref.current.value : "0");
        }
        if (props.rel.question_2) {
            q2 = parseFloat(q2Ref.current.value ? q2Ref.current.value : "0");
        }

        props.rel.answer_1 = q1;
        props.rel.answer_2 = q2;

        props.onSave(props.rel, props.idx);
    }

    const enterPress = (e: any) => {
        if (e.code == "Enter") {
            save()
        }
    }

    return <div className={"answer-card h-full flex-col items-center justify-center gap-4 " + props.className}>
        {
            props.rel.question_1 ?
                <div className="w-full flex flex-col">
                    <span className="block text-sm font-medium leading-6 text-gray-900">{props.rel.question_1}</span>
                    <input onKeyUp={props.rel.question_2 ? () => { } : enterPress} autoFocus type={"number"} ref={q1Ref} placeholder="Respuesta" className="mt-2 block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6" defaultValue={props.rel.answer_1 ? props.rel.answer_1 : undefined} />
                </div>
                :
                <></>
        }

        {
            props.rel.question_2 ?
                <div className="w-full flex flex-col">
                    <span className="block text-sm font-medium leading-6 text-gray-900">{props.rel.question_2}</span>
                    <input onKeyUp={enterPress} type={"number"} ref={q2Ref} placeholder="Respuesta" className="mt-2 block w-full px-4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6" defaultValue={props.rel.answer_2 ? props.rel.answer_2 : undefined} />
                </div>
                :
                <></>
        }
        <div className="flex flex-row items-center justify-end gap-2 w-full">

            {
                props.idx > 0 ?
                    <button onClick={props.onPrev} className="inline-flex justify-center rounded-md py-2 px-3 text-sm font-semibold text-sky-600 border-sky-600 border outline-sky-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Anterior</button>
                    :
                    <></>
            }
            <button onClick={save} type="submit" className=" inline-flex justify-center rounded-md py-2 px-3 text-sm font-semibold text-white bg-sky-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Siguiente</button>
        </div>
    </div>
}
export default AnswerCard;